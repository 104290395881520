/* eslint-disable camelcase */
/* eslint-disable no-const-assign */
/* eslint-disable space-before-function-paren */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import Masonry from 'masonry-layout'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger.js'
import device from 'current-device'
// import { SplitText } from 'gsap/SplitText.js'
import { Howl, Howler } from 'howler'

import * as THREE from 'three'
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls'

import { CubeLogo } from './js/CubeLogo.js'
import { Cuboid } from './js/Cuboid.js'
import { Cube } from './js/Cube.js'
import { NoToneMapping } from 'three'

console.log("It's working!")

gsap.registerPlugin(ScrollTrigger)
// gsap.registerPlugin(SplitText)

let msnry

window.addEventListener('beforeunload', (event) => {
  const isMailTo = document.activeElement.protocol === 'mailto:'
  const isTel = document.activeElement.protocol === 'tel:'
  console.log(document.activeElement.protocol)
  if (!isMailTo && !isTel) {
    activateLoader()
  }
})

window.addEventListener('load', (event) => {
  deactivateLoader()
})

// eslint-disable-next-line space-before-function-paren
function activateLoader() {
  console.log('activateLoader')
  document.body.classList.add('op-prevent-scrolling')
  gsap.set('.op-loader-wrapper', {
    autoAlpha: 1,
    delay: 0
  })
}

// eslint-disable-next-line space-before-function-paren
function deactivateLoader() {
  console.log('deactivateLoader')
  gsap.to('.op-loader-wrapper', {
    autoAlpha: 0,
    delay: 0,
    onComplete: function () {
      document.body.classList.remove('op-prevent-scrolling')
      scrollApp()
      if (device.desktop()) {
        setTimeout(() => {
          if (
            document.body.contains(
              document.getElementById('op-intro-mouth-video-container')
            )
          ) {
            initAudio()
          }
        }, 2000)
      }
    }
  })
}

document.addEventListener('DOMContentLoaded', (event) => {
  console.log('DOMContentLoaded')
  console.log('device.mobile() === %s', device.mobile())

  initApp()
})

// eslint-disable-next-line space-before-function-paren
function initApp() {
  console.log('initApp')

  ini3DObjects()
  initBgTextAni()
  initBgImageAni()
  initColoredText()
  initColoredBgText()
  initMasonry()

  window.addEventListener('resize', () => {
    onResize()
  })
}

// eslint-disable-next-line space-before-function-paren
function scrollApp() {
  const hash = window.location.pathname.substring(4)
  if (hash !== '') {
    window.scrollTo(0, 0)
    // eslint-disable-next-line space-before-function-paren
    setTimeout(function () {
      window.scrollTo(0, 0)
    }, 1)
    console.log(hash)
    const anchor = document.getElementById(hash)
    if (anchor) {
      const posTop = anchor.offsetTop
      console.log(posTop)
      window.scrollTo(0, posTop)
    }
  }
}

// eslint-disable-next-line space-before-function-paren
function initBgTextAni() {
  console.log('initBgTextAni')

  // Background Text Horizontal

  gsap.to('#op-bg-copy-header', {
    x: -100,
    y: 200,
    ease: 'none',
    force3D: true,
    rotation: 0.01,
    scrollTrigger: {
      trigger: '.op-bg-copy-container',
      start: 'top top',
      scrub: 0
    }
  })

  gsap.to('#op-who-bg-copy-1', {
    x: -500,
    ease: 'none',
    force3D: true,
    rotation: 0.01,
    scrollTrigger: {
      trigger: '#section-who',
      scrub: 0
    }
  })

  gsap.to('#op-who-bg-copy-2', {
    x: 300,
    ease: 'none',
    force3D: true,
    rotation: 0.01,
    scrollTrigger: {
      trigger: '#section-who',
      scrub: 0
    }
  })

  gsap.to('#op-who-bg-copy-3', {
    x: -200,
    ease: 'none',
    force3D: true,
    rotation: 0.01,
    scrollTrigger: {
      trigger: '#section-who',
      scrub: 0
    }
  })

  // Background Text Vertical
  gsap.set('#op-intro-bg-copy-1', {
    rotation: -90,
    transformOrigin: '50% 0%',
    force3D: true
  })

  gsap.set('#op-intro-bg-copy-2', {
    rotation: 90,
    transformOrigin: '50% 100%',
    force3D: true
  })

  // gsap.set('#op-intro-bg-copy-3', {
  //   rotation: 90,
  //   transformOrigin: '50% 100%',
  //   force3D: true
  // })

  // gsap.set('#op-intro-bg-copy-4', {
  //   rotation: -90,
  //   transformOrigin: '50% 100%',
  //   force3D: true
  // })

  let yCopyDist1 = -600
  let yCopyDist2 = -300
  let yCopyDist3 = -200
  let yCopyDist4 = -1200
  if (device.mobile()) {
    yCopyDist1 = -100
    yCopyDist2 = -200
    yCopyDist3 = -50
    yCopyDist4 = -120
  }

  gsap.to('#op-intro-bg-copy-1', {
    y: yCopyDist1,
    ease: 'none',
    force3D: true,
    duration: 1,
    scrollTrigger: {
      trigger: '#op-intro-bg-copy-1',
      start: 'top bottom',
      scrub: 0
      // markers: true
    }
  })

  gsap.to('#op-intro-bg-copy-2', {
    y: yCopyDist2,
    ease: 'none',
    force3D: true,
    scrollTrigger: {
      trigger: '#op-intro-bg-copy-2',
      scrub: 0,
      start: 'top bottom',
      end: 'bottom+=50% top'
      // markers: true
    }
  })

  gsap.to('#op-intro-bg-copy-3', {
    y: yCopyDist3,
    ease: 'none',
    force3D: true,
    scrollTrigger: {
      trigger: '#op-intro-bg-copy-3',
      start: 'top bottom',
      scrub: 0
      // markers: true
    }
  })

  gsap.to('#op-intro-bg-copy-4', {
    y: yCopyDist4,
    ease: 'none',
    force3D: true,
    scrollTrigger: {
      trigger: '#op-intro-bg-copy-4',
      scrub: 0,
      start: 'top bottom',
      end: 'bottom+=200% top'
      // markers: true
    }
  })
}

// eslint-disable-next-line space-before-function-paren
function initBgImageAni() {
  console.log('initBgImageAni')

  // Image about
  gsap.to('.op-intro-image', {
    y: 150,
    ease: 'none',
    scrollTrigger: {
      trigger: '.op-intro-image',
      scrub: 0,
      start: 'top bottom',
      end: 'bottom+=75% top'
    }
  })

  // Copy employee
  // ScrollTrigger.matchMedia({
  //   // desktop
  //   '(min-width: 800px)': function () {
  //     gsap.to('.op-employee-content', {
  //       yPercent: 25,
  //       ease: 'none',
  //       scrollTrigger: {
  //         trigger: '.op-employee-content',
  //         toggleActions: 'restart pause resume pause',
  //         scrub: 0,
  //         start: 'top bottom',
  //         end: 'bottom+=25% top'
  //       }
  //     })
  //   }
  // })

  // Copy Team
  // ScrollTrigger.matchMedia({
  //   // desktop
  //   '(min-width: 800px)': function () {
  //     gsap.to('.op-copy-container', {
  //       yPercent: 15,
  //       ease: 'none',
  //       scrollTrigger: {
  //         trigger: '.op-copy-container',
  //         scrub: 0
  //       }
  //     })
  //   }
  // })

  // Image Fullscreen
  gsap.to('.op-who-bg-image', {
    yPercent: 50,
    ease: 'none',
    scrollTrigger: {
      trigger: '#section-who',
      toggleActions: 'restart pause resume pause',
      scrub: 0
    }
  })
}

// eslint-disable-next-line space-before-function-paren
function initColoredText() {
  console.log('initColoredText')

  const colored = document.getElementsByClassName('colored')

  for (let i = 0; i < colored.length; i++) {
    const element = colored[i]

    const dur = Math.ceil(element.innerHTML.length / 24)

    // TEST: BG Lines Animation
    gsap.to(element, {
      duration: dur,
      backgroundPosition: 0,
      ease: 'power3.inOut',
      scrollTrigger: {
        trigger: element,
        toggleActions: 'restart pause resume pause',
        start: 'top bottom'
        // markers: true
      }
    })
  }
}

// eslint-disable-next-line space-before-function-paren
function initColoredBgText() {
  console.log('initColoredBgText')

  const bgColored = document.getElementsByClassName('bg-colored')

  for (let i = 0; i < bgColored.length; i++) {
    const element = bgColored[i]

    const dur = Math.ceil(element.innerHTML.length / 24)
    gsap.to(element, {
      duration: dur,
      xPercent: 0,
      backgroundSize: '100% 100%',
      ease: 'power3.inOut',
      scrollTrigger: {
        trigger: element,
        toggleActions: 'restart pause resume pause',
        start: 'top bottom'
        // markers: true
      }
    })
  }
}

// eslint-disable-next-line space-before-function-paren
function ini3DObjects() {
  console.log('ini3DObjects')

  const cubeLogo = new CubeLogo()
  cubeLogo.initCubeLogo(gsap, THREE, TrackballControls)

  const cuboidContainer = document.getElementById('op-cuboid-container')
  if (typeof cuboidContainer !== 'undefined' && cuboidContainer != null) {
    const cuboid = new Cuboid()
    cuboid.initCuboid(gsap, ScrollTrigger, THREE)
  }

  const cubeContainer = document.getElementById('op-cube-container')
  if (typeof cubeContainer !== 'undefined' && cubeContainer != null) {
    const cube = new Cube()
    cube.initCube(gsap, ScrollTrigger, THREE, TrackballControls)
  }
}

// eslint-disable-next-line space-before-function-paren
function initMasonry() {
  console.log('initMasonry')

  const opGallery = document.getElementById('op-projects')

  if (typeof opGallery !== 'undefined' && opGallery != null) {
    msnry = new Masonry(opGallery, {
      originLeft: true,
      percentPosition: true,
      horizontalOrder: true,
      columnWidth: '.grid-sizer',
      itemSelector: '.grid-item'
    })

    setTimeout(function () {
      msnry.layout()
      initOutro()
    }, 1000)

    setTimeout(function () {
      initVideos()
    }, 1500)

    setTimeout(function () {
      msnry.layout()
    }, 2000)

    setTimeout(function () {
      initVideos()
    }, 2500)

    setTimeout(function () {
      msnry.layout()
    }, 3000)

    setTimeout(function () {
      initVideos()
    }, 3500)
  }
}

// eslint-disable-next-line space-before-function-paren
function initVideos() {
  console.log('initVideos')

  const allVideos = gsap.utils.toArray('.op-video-container')
  // console.log(allVideos)

  allVideos.forEach((videoContainer, _i) => {
    const videoElem = videoContainer.querySelector('video')

    ScrollTrigger.create({
      trigger: videoElem,
      start: 'top 100%',
      end: 'bottom 0%',
      // markers: true,
      onEnter: () => videoElem.play(),
      onEnterBack: () => videoElem.play(),
      onLeave: () => {
        const playPromise = videoElem.play()

        if (playPromise !== undefined) {
          playPromise
            .then((_) => {
              videoElem.pause()
            })
            .catch((_error) => {
              videoElem.pause()
            })
        }
      },
      onLeaveBack: () => videoElem.pause()
    })
  })
}

// eslint-disable-next-line space-before-function-paren
function initOutro() {
  console.log('initOutro')

  gsap.to('.line-1', {
    height: '100%',
    ease: 'none',
    scrollTrigger: {
      trigger: '.op-vertical-line-container-1',
      toggleActions: 'restart pause pause reverse',
      start: 'top 80%',
      end: 'bottom 100%',
      scrub: 0
      // markers: true
    }
  })

  gsap.to('.line-2', {
    height: '100%',
    ease: 'none',
    scrollTrigger: {
      trigger: '.op-vertical-line-container-2',
      toggleActions: 'restart pause pause reverse',
      start: 'top 95%',
      end: 'bottom 100%',
      scrub: 0
      // markers: true
    }
  })

  gsap.from('#op-thanks', {
    autoAlpha: 0,
    y: 40,
    ease: 'power3.out',
    duration: 1,
    scrollTrigger: {
      trigger: '#op-thanks',
      toggleActions: 'restart pause pause reverse',
      start: 'top bottom',
      end: 'bottom top'
      // scrub: 0,
      // markers: true
    }
  })

  gsap.from('#op-bussi', {
    autoAlpha: 0,
    y: 40,
    ease: 'power3.out',
    duration: 1,
    scrollTrigger: {
      trigger: '#op-bussi',
      toggleActions: 'restart pause pause reverse',
      start: 'top bottom',
      end: 'bottom top'
      // scrub: 0,
      // markers: true
    }
  })

  gsap.to('.line-3', {
    height: '100%',
    ease: 'none',
    scrollTrigger: {
      trigger: '.op-vertical-line-container-3',
      toggleActions: 'restart pause pause reverse',
      start: 'top 95%',
      end: 'bottom 100%',
      scrub: 0
      // markers: true
    }
  })

  gsap.from('#op-dancing', {
    autoAlpha: 0,
    y: 80,
    ease: 'power3.out',
    duration: 1,
    scrollTrigger: {
      trigger: '#op-dancing',
      toggleActions: 'restart pause pause reverse',
      start: 'top bottom',
      end: 'bottom top'
      // scrub: 0,
      // markers: true
    }
  })

  gsap.from('#op-emoji', {
    autoAlpha: 0,
    scale: 0,
    ease: 'back.out(5)',
    force3D: true,
    scrollTrigger: {
      trigger: '#op-emoji',
      toggleActions: 'restart pause pause reverse',
      start: 'top 90%'
      // end: 'bottom top',
      // markers: true
    }
  })
}

let isMouthActive = false
let isMouthVisible = false
let isSoundPausing = false
let soundHowl
let forwardHowl
let activeSoundId = 0
let mouthVideo
let mouthText
let toggleSound
let activeSoundIds = []
const playedSoundIds = []
let audioTexts = []
let mouthOnText = ''
let mouthOffText = ''
let lastScrollTop = 0
let timer = null
let isScrolling = false
let sprites
// eslint-disable-next-line space-before-function-paren
function initAudio() {
  console.log('initAudio')

  toggleSound = document.getElementById('op-intro-mouth-video-container')
  mouthVideo = document.getElementById('op-mouth-video')
  mouthText = document.getElementById('op-mouth-text')
  mouthOnText = document.getElementById('op-mouth-on-text').innerHTML
  mouthOffText = document.getElementById('op-mouth-off-text').innerHTML

  const spriteDE = {
    0: [1000, 2000],
    1: [3000, 9500],
    2: [12500, 2400],
    3: [14900, 9000],
    4: [23900, 10500],
    5: [34400, 8500],
    6: [42900, 3500],
    7: [46400, 1750],
    8: [48150, 1750],
    9: [49900, 2500],
    10: [52500, 3900],
    11: [56400, 300],
    12: [56700, 400],
    13: [57100, 8500],
    14: [65500, 24500],
    15: [90500, 26000],
    16: [116500, 16000],
    17: [132500, 24500]
  }

  const spriteEN = {
    0: [0, 1500],
    1: [1500, 9000],
    2: [10500, 2250],
    3: [12750, 9000],
    4: [21750, 13200],
    5: [34950, 10500],
    6: [45450, 4000],
    7: [49450, 2750],
    8: [52250, 1950],
    9: [54200, 3000],
    10: [57500, 4300],
    11: [61800, 1000],
    12: [62800, 9500],
    13: [72000, 28500],
    14: [100500, 29000],
    15: [129500, 19000],
    16: [148500, 26500]
  }

  if (document.documentElement.lang === 'de') {
    sprites = spriteDE
  } else {
    sprites = spriteEN
  }

  soundHowl = new Howl({
    src: ['assets/audio/mund_' + document.documentElement.lang + '.mp3'],
    sprite: sprites,
    rate: 1
  })

  forwardHowl = new Howl({
    src: ['assets/audio/forward.mp3'],
    rate: 1,
    loop: true
  })

  soundHowl.once('load', function () {
    console.log('soundHowl loaded')
    if (activeSoundIds.length > 0) {
      showMouth()
    }

    // const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    // if (isSafari) {
    //   stopSound()
    // } else {
    //   playSound()
    // }
  })

  soundHowl.on('end', function (id) {
    console.log('soundHowl end')
    onEnd()
  })

  soundHowl.on('play', function () {
    console.log('soundHowl play')
  })

  audioTexts = document.getElementsByClassName('audiotext')

  for (let i = 0; i < audioTexts.length; i++) {
    const audioEL = audioTexts[i]
    ScrollTrigger.create({
      trigger: audioEL,
      start: 'top 100%',
      end: 'bottom-=100% 0',
      // markers: true,
      onEnter: () => onEnterAudioEL(i),
      onEnterBack: () => onEnterBackAudioEL(i),
      onLeave: () => onLeaveAudioEL(i),
      onLeaveBack: () => onLeaveBackAudioEL(i)
    })
  }

  // ScrollTrigger.refresh()

  // eslint-disable-next-line space-before-function-paren
  toggleSound.onclick = function () {
    isMouthActive = !isMouthActive

    if (isMouthActive) {
      playSound()
    } else {
      pauseSound()
    }
  }

  toggleSound.onmouseover = function () {
    // console.log('onmouseover')
  }

  toggleSound.onmouseout = function () {
    // console.log('onmouseout')
  }

  window.addEventListener(
    'scroll',
    function () {
      const st = window.pageYOffset || document.documentElement.scrollTop
      if (st > lastScrollTop) {
        checkScrolling(0)
      } else {
        checkScrolling(1)
      }
      lastScrollTop = st <= 0 ? 0 : st
    },
    false
  )

  document.addEventListener('mousemove', onMouseMove)
}

function onMouseMove(e) {
  // console.log('onMouseMove', e)
  gsap.to(toggleSound, {
    duration: 1,
    css: { left: e.clientX, top: e.clientY },
    ease: 'power1'
  })
}

function checkScrolling(dir) {
  console.log('checkScrolling', dir)
  if (!isScrolling) {
    isScrolling = true
    if (dir === 0) {
      if (isMouthActive) {
        console.log('downscroll started')
        soundHowl.pause()
        forwardHowl.play()
        mouthVideo.play()
      }
    } else {
      if (isMouthActive) {
        console.log('upscroll started')
        soundHowl.pause()
        forwardHowl.play()
        mouthVideo.play()
      }
    }
  }
  if (timer !== null) {
    clearTimeout(timer)
  }
  timer = setTimeout(function () {
    // do something
    if (dir === 0) {
      if (isMouthActive) {
        console.log('downscroll stopped')
        forwardHowl.stop()
        playSound()
      }
    } else {
      if (isMouthActive) {
        console.log('upscroll stopped')
        forwardHowl.stop()
        playSound()
      }
    }
    isScrolling = false
  }, 150)
}

function checkMouth() {
  if (activeSoundIds.length === 0) {
    hideMouth()
  } else {
    showMouth()
  }
}

function showMouth() {
  if (!isMouthVisible) {
    gsap.to(toggleSound, { autoAlpha: 1 })
    isMouthVisible = true
  }
}

function hideMouth() {
  if (isMouthVisible) {
    gsap.to(toggleSound, { autoAlpha: 0 })
    isMouthVisible = false
  }
}

// eslint-disable-next-line space-before-function-paren
function onEnd() {
  console.log('onEnd ', activeSoundId)

  if (activeSoundIds.includes(activeSoundId)) {
    activeSoundIds.shift()
    if (activeSoundId === audioTexts.length || activeSoundIds.length === 0) {
      mouthVideo.pause()
      // isMouthActive = false
      // stopSound()
      // pauseSound()
    } else {
      loopSound()
    }
  } else {
    stopSound()
  }

  console.log('onEnd activeSoundIds', activeSoundIds)
}

function loopSound() {
  console.log('')
  console.log('loopSound')
  console.log('playedSoundIds ', playedSoundIds)

  activeSoundId++

  mouthText.innerHTML = mouthOffText
  mouthVideo.play()
  soundHowl.play(activeSoundId.toString())
  isMouthActive = true
  isSoundPausing = false

  console.log('loopSound activeSoundIds', activeSoundIds)
  console.log('')
}

// eslint-disable-next-line space-before-function-paren
function playSound() {
  chechForEl()
  console.log('')
  console.log('playSound')
  console.log('isSoundPausing', isSoundPausing)

  activeSoundId = activeSoundIds[0]

  mouthText.innerHTML = mouthOffText
  mouthVideo.play()

  if (isSoundPausing) {
    soundHowl.play()
  } else {
    soundHowl.play(activeSoundId.toString())
  }

  isMouthActive = true
  isSoundPausing = false

  console.log('playSound activeSoundIds', activeSoundIds)
  console.log('')
}

function pauseSound() {
  console.log('')
  console.log('pauseSound')

  mouthText.innerHTML = mouthOnText
  mouthVideo.pause()
  soundHowl.pause()
  isMouthActive = false
  isSoundPausing = true

  console.log('pauseSound activeSoundIds', activeSoundIds)
  console.log('')
}

// eslint-disable-next-line space-before-function-paren
function stopSound() {
  console.log('')
  console.log('stopSound')

  mouthText.innerHTML = mouthOnText
  mouthVideo.pause()
  soundHowl.pause()
  isMouthActive = false
  isSoundPausing = false

  chechForEl()
  checkMouth()

  console.log('stopSound activeSoundIds', activeSoundIds)
  console.log('')
}

// eslint-disable-next-line space-before-function-paren
function onEnterAudioEL(id) {
  console.log('')
  console.log('onEnterAudioEL', id)
  console.log('add id ', id, ' to activeSoundIds at END')

  // add element at the end
  // activeSoundIds.push(id)

  chechForEl()
  checkMouth()

  console.log('onEnterAudioEL activeSoundIds', activeSoundIds)
  console.log('')
}

// eslint-disable-next-line space-before-function-paren
function onLeaveAudioEL(id) {
  console.log('')
  console.log('onLeaveAudioEL', id, activeSoundId)
  console.log('delete id ', id, ' from activeSoundIds at START')

  // delete element at the start
  // if (activeSoundIds.includes(id)) {
  //   activeSoundIds.shift()
  // }

  chechForEl()
  checkMouth()

  console.log('onLeaveAudioEL activeSoundIds', activeSoundIds)
  console.log('')
}

// eslint-disable-next-line space-before-function-paren
function onEnterBackAudioEL(id) {
  console.log('')
  console.log('onEnterBackAudioEL', id)
  console.log('add id ', id, ' to activeSoundIds at START')

  // add element at the start
  // activeSoundIds.unshift(id)

  chechForEl()
  checkMouth()

  console.log('onEnterBackAudioEL activeSoundIds', activeSoundIds)
  console.log('')
}

// eslint-disable-next-line space-before-function-paren
function onLeaveBackAudioEL(id) {
  console.log('')
  console.log('onLeaveBackAudioEL', id)
  console.log('delete id ', id, ' from activeSoundIds at END')

  // delete last element
  // activeSoundIds.pop(id)

  chechForEl()
  checkMouth()

  console.log('onLeaveBackAudioEL activeSoundIds', activeSoundIds)
  console.log('')
}

function chechForEl() {
  console.log('')
  console.log('chechForEl')

  isSoundPausing = false
  activeSoundIds = []

  for (let i = 0; i < audioTexts.length; i++) {
    const audioEL = audioTexts[i]
    if (isElementInViewport(audioEL, i)[0]) {
      activeSoundIds.push(isElementInViewport(audioEL, i)[2])
    }
  }

  console.log('chechForEl activeSoundIds', activeSoundIds)
  console.log('')
}

function isElementInViewport(el, i) {
  // Special bonus for those using jQuery
  if (typeof jQuery === 'function' && el instanceof jQuery) {
    el = el[0]
  }

  const rect = el.getBoundingClientRect()

  return [
    rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight ||
          document.documentElement.clientHeight) /* or $(window).height() */ &&
      rect.right <=
        (window.innerWidth ||
          document.documentElement.clientWidth) /* or $(window).width() */,
    el,
    i
  ]
}

// eslint-disable-next-line space-before-function-paren
function onResize() {
  console.log('onResize')
}
