/* eslint-disable space-before-function-paren */
/* eslint-disable no-undef */
let scene
let camera
let renderer
let container
let gsap
let ScrollTrigger
let THREE
let TrackballControls

class Cube {
  initCube(g, scroll, three, trackballControls) {
    console.log('init Cube Logo')

    gsap = g
    ScrollTrigger = scroll
    THREE = three
    TrackballControls = trackballControls

    gsap.registerPlugin(ScrollTrigger)

    container = document.getElementById('op-cube-container')

    const containerWidth = container.clientWidth
    const containerHeight = container.clientHeight

    scene = new THREE.Scene()

    camera = new THREE.PerspectiveCamera(
      29,
      containerWidth / containerHeight,
      0.1,
      1000
    )

    renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true
    })
    renderer.setSize(containerWidth, containerHeight)

    container.appendChild(renderer.domElement)

    const controls = new TrackballControls(camera, renderer.domElement)
    controls.noPan = true
    controls.noZoom = true
    controls.rotateSpeed = 2
    controls.dynamicDampingFactor = 0.1

    // eslint-disable-next-line space-before-function-paren
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.75)
    ambientLight.position.set(0, 0, 0)
    camera.add(ambientLight)

    // eslint-disable-next-line space-before-function-paren
    const light1 = new THREE.PointLight(0xffffff, 0.85)
    light1.position.set(150, 100, -50)
    camera.add(light1)

    // eslint-disable-next-line space-before-function-paren
    const light2 = new THREE.PointLight(0xffffff, 0.35)
    light2.position.set(-150, 100, -50)
    camera.add(light2)

    // eslint-disable-next-line space-before-function-paren
    const light3 = new THREE.PointLight(0xffffff, 0.35)
    light3.position.set(0, 100, -50)
    camera.add(light3)

    // eslint-disable-next-line space-before-function-paren
    const light4 = new THREE.PointLight(0xffffff, 0.35)
    light4.position.set(0, 100, 0)
    camera.add(light4)

    const boxWidth = 1
    const boxHeight = 1
    const boxDepth = 1
    const geometry = new THREE.BoxGeometry(boxWidth, boxHeight, boxDepth)

    const cubeTextures = [
      '/assets/images/cube_texture_p.jpg',
      '/assets/images/cube_texture_p.jpg',
      '/assets/images/cube_texture_n.jpg',
      '/assets/images/cube_texture_n.jpg',
      '/assets/images/cube_texture_o.jpg',
      '/assets/images/cube_texture_o.jpg'
    ]
    const textureAll = []

    for (let i = 0; i < cubeTextures.length; i++) {
      const cubeTexture = cubeTextures[i]

      const texture = new THREE.TextureLoader().load(cubeTexture)
      textureAll.push(
        new THREE.MeshPhongMaterial({
          // eslint-disable-next-line space-before-function-paren
          map: texture
        })
      )
    }

    const mesh = new THREE.Mesh(geometry, textureAll)
    mesh.castShadow = true
    mesh.receiveShadow = true
    mesh.rotation.x = 0
    mesh.rotation.y = 0
    mesh.rotation.z = 0

    scene.add(mesh)
    scene.add(camera)

    camera.position.set(2, -2, 2)

    controls.update()
    renderer.render(scene, camera)

    const render = function () {
      requestAnimationFrame(render)
      controls.update()
      renderer.render(scene, camera)
    }

    render()

    window.addEventListener('resize', () => {
      this.onResize()
    })

    gsap.to(mesh.rotation, {
      y: 4,
      ease: 'none',
      scrollTrigger: {
        trigger: container,
        scrub: 0.25
        // markers: true
      }
    })
  }

  onResize() {
    console.log('onResize')

    const containerWidth = container.clientWidth
    const containerHeight = container.clientHeight

    renderer.setSize(containerWidth, containerHeight)
    camera.aspect = containerWidth / containerHeight
    camera.updateProjectionMatrix()
  }
}
export { Cube }
