/* eslint-disable space-before-function-paren */
/* eslint-disable no-undef */
let scene
let camera
let renderer
let container
let THREE
let TrackballControls
let gsap

class CubeLogo {
  initCubeLogo(g, three, trackballControls) {
    console.log('init Cube Logo')

    gsap = g
    THREE = three
    TrackballControls = trackballControls

    container = document.getElementById('op-logo-cube-container')
    const containerWidth = container.clientWidth
    const containerHeight = container.clientHeight

    scene = new THREE.Scene()

    camera = new THREE.PerspectiveCamera(
      30,
      containerWidth / containerHeight,
      0.01,
      1000
    )

    renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true
    })
    renderer.setSize(containerWidth, containerHeight)

    container.appendChild(renderer.domElement)

    const controls = new TrackballControls(camera, renderer.domElement)
    controls.noPan = true
    controls.noZoom = true
    controls.rotateSpeed = 2
    controls.dynamicDampingFactor = 0.1

    // eslint-disable-next-line space-before-function-paren
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.75)
    ambientLight.position.set(0, 0, 0)
    camera.add(ambientLight)

    // eslint-disable-next-line space-before-function-paren
    const light1 = new THREE.PointLight(0xffffff, 0.95)
    light1.position.set(150, 100, -50)
    camera.add(light1)

    // eslint-disable-next-line space-before-function-paren
    const light2 = new THREE.PointLight(0xffffff, 0.35)
    light2.position.set(-150, 100, -50)
    camera.add(light2)

    // eslint-disable-next-line space-before-function-paren
    const light3 = new THREE.PointLight(0xffffff, 0.35)
    light3.position.set(0, 100, -50)
    camera.add(light3)

    const boxWidth = 1
    const boxHeight = 1
    const boxDepth = 1
    const geometry = new THREE.BoxGeometry(boxWidth, boxHeight, boxDepth)

    const cubeTextures = [
      '/assets/images/cube_texture_p_small.jpg',
      '/assets/images/cube_texture_p_small.jpg',
      '/assets/images/cube_texture_n_small.jpg',
      '/assets/images/cube_texture_n_small.jpg',
      '/assets/images/cube_texture_o_small.jpg',
      '/assets/images/cube_texture_o_small.jpg'
    ]
    const textureAll = []

    for (let i = 0; i < cubeTextures.length; i++) {
      const cubeTexture = cubeTextures[i]

      const texture = new THREE.TextureLoader().load(cubeTexture)
      textureAll.push(
        new THREE.MeshPhongMaterial({
          // eslint-disable-next-line space-before-function-paren
          map: texture
        })
      )
    }

    const mesh = new THREE.Mesh(geometry, textureAll)
    mesh.castShadow = true
    mesh.receiveShadow = true
    mesh.rotation.x = 0

    scene.add(mesh)
    scene.add(camera)

    camera.position.set(2, -2, 2)
    controls.update()
    renderer.render(scene, camera)

    const render = function () {
      requestAnimationFrame(render)
      controls.update()
      renderer.render(scene, camera)
    }

    render()

    gsap.to(mesh.rotation, {
      y: 15.7,
      ease: 'power3.inOut',
      duration: 2,
      delay: 1
    })
  }
}
export { CubeLogo }
