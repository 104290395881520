/* eslint-disable space-before-function-paren */
/* eslint-disable no-undef */
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControlsCuboid'

let scene
let camera
let renderer
let container
let gsap
let ScrollTrigger
let THREE
// let TrackballControls

class Cuboid {
  initCuboid(g, scroll, three) {
    console.log('init Cuboid')
    console.log(document.documentElement.lang)

    gsap = g
    ScrollTrigger = scroll
    THREE = three

    gsap.registerPlugin(ScrollTrigger)

    container = document.getElementById('op-cuboid-container')

    const containerWidth = container.clientWidth
    const containerHeight = container.clientHeight

    scene = new THREE.Scene()

    camera = new THREE.PerspectiveCamera(
      55,
      containerWidth / containerHeight,
      0.1,
      1000
    )

    renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true
    })
    renderer.setSize(containerWidth, containerHeight)

    container.appendChild(renderer.domElement)

    const controls = new TrackballControls(camera, renderer.domElement)
    controls.noPan = true
    controls.noZoom = true
    controls.rotateSpeed = 2
    controls.dynamicDampingFactor = 0.1

    // eslint-disable-next-line space-before-function-paren
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.65)
    ambientLight.position.set(0, 0, 0)
    camera.add(ambientLight)

    // eslint-disable-next-line space-before-function-paren
    const light1 = new THREE.PointLight(0xffffff, 0.25)
    light1.position.set(-75, 150, 75)
    camera.add(light1)

    const light2 = new THREE.PointLight(0xffffff, 0.25)
    light2.position.set(0, 150, 75)
    camera.add(light2)

    const light3 = new THREE.PointLight(0xffffff, 0.25)
    light3.position.set(75, 150, 75)
    camera.add(light3)

    const boxWidth = 3.4
    const boxHeight = 0.9
    const boxDepth = 0.9
    const geometry = new THREE.BoxGeometry(boxWidth, boxHeight, boxDepth)

    const cubeTextures = [
      '/assets/images/cube_texture_p.jpg',
      '/assets/images/cube_texture_p.jpg',
      '/assets/images/cuboid_texture_top_01_' +
        document.documentElement.lang +
        '.jpg',
      '/assets/images/cuboid_texture_top_02_' +
        document.documentElement.lang +
        '.jpg',
      '/assets/images/cuboid_texture_bottom_02_' +
        document.documentElement.lang +
        '.jpg',
      '/assets/images/cuboid_texture_bottom_01_' +
        document.documentElement.lang +
        '.jpg'
    ]
    const textureAll = []

    for (let i = 0; i < cubeTextures.length; i++) {
      const cubeTexture = cubeTextures[i]

      const texture = new THREE.TextureLoader().load(cubeTexture)
      textureAll.push(
        new THREE.MeshPhongMaterial({
          // eslint-disable-next-line space-before-function-paren
          map: texture
        })
      )
    }

    const mesh = new THREE.Mesh(geometry, textureAll)
    mesh.castShadow = true
    mesh.receiveShadow = true
    mesh.rotation.x = 0
    mesh.rotation.y = 0
    mesh.rotation.z = 0

    scene.add(mesh)
    scene.add(camera)

    // camera.position.set(0, 0, 2.3)
    camera.position.set(0, 0, 2.3)

    controls.update()
    renderer.render(scene, camera)

    const render = function () {
      requestAnimationFrame(render)
      controls.update()
      renderer.render(scene, camera)
    }

    render()

    window.addEventListener('resize', () => {
      this.onResize()
    })

    const cubeTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: container,
        scrub: 0.25
        // markers: true
      }
    })

    // cubeTimeline.fromTo(
    //   '#op-cuboid-container canvas',
    //   {
    //     yPercent: -25,
    //     ease: 'none'
    //   },
    //   {
    //     yPercent: 25,
    //     ease: 'none'
    //   },
    //   'simultaneously'
    // )
    cubeTimeline.to(
      mesh.rotation,
      {
        x: 4,
        ease: 'none'
      },
      'simultaneously'
    )
  }

  onResize() {
    console.log('onResize')

    const containerWidth = container.clientWidth
    const containerHeight = container.clientHeight

    renderer.setSize(containerWidth, containerHeight)
    camera.aspect = containerWidth / containerHeight
    camera.updateProjectionMatrix()
  }
}
export { Cuboid }
